import HeaderBar from './HeaderBar.js';
import Particles from './Particles.js';
import HeaderBody from './HeaderBody.js';

const Header = (props) => {
	const styles = {
		header: {
			padding: `3.75rem 4.38rem`,
			backgroundColor: '#df11ff',
			background: 'linear-gradient(#de15fe, #32caf9)',
			color: '#fff',
			position: 'relative',
			overflow: 'hidden'
		}
	};

	return (
		<div id="HOME" style={styles.full_header}>
			<div className="header" style={styles.header}>
				<Particles />
				<HeaderBar />
				{props.children}
			</div>
			<img style={{ marginTop: -1 }} src="/gradientwave.svg" alt="" />
		</div>
	);
};

export default Header;
