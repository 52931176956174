import React from 'react';
import { Component } from 'react';
// import imgSrc from './glitch.png';


class Sketch extends Component {
    constructor() {
        super();
        this.renderRef = React.createRef();
        this.state = {
            x: 1920,
            y: 1080,
            isLoaded: false,
            glitch: null,
        };
    }

    componentDidMount() {
        const p5 = require('p5');
        let isLoaded = false;
        let glitch;
        this.sketch = new p5(p => {
            let glitch;
            class Glitch {
                constructor(img) {
                    this.channelLen = 4;
                    this.imgOrigin = img;
                    this.imgOrigin.loadPixels();
                    this.copyData = [];
                    this.flowLineImgs = [];
                    this.shiftLineImgs = [];
                    this.shiftRGBs = [];
                    this.scatImgs = [];
                    this.throughFlag = true;
                    this.copyData = new Uint8ClampedArray(this.imgOrigin.pixels);
            
                    // flow line
                    for (let i = 0; i < 1; i++) {
                        let o = {
                            pixels: null,
                            t1: p.floor(p.random(0, 1000)),
                            speed: p.floor(p.random(4, 24)),
                            randX: p.floor(p.random(24, 80))
                        };
                        this.flowLineImgs.push(o);
                    }
            
                    // shift line
                    for (let i = 0; i < 6; i++) {
                        let o = null;
                        this.shiftLineImgs.push(o);
                    }
            
                    // shift RGB
                    for (let i = 0; i < 1; i++) {
                        let o = null;
                        this.shiftRGBs.push(o);
                    }
            
                    // scat imgs
                    for (let i = 0; i < 3; i++) {
                        let scatImg = {
                            img: null,
                            x: 0,
                            y: 0
                        };
                        this.scatImgs.push(scatImg);
                    }
                }
            
                replaceData(destImg, srcPixels) {
                    for (let y = 0; y < destImg.height; y++) {
                        for (let x = 0; x < destImg.width; x++) {
                            let r, g, b, a;
                            let index;
                            index = (y * destImg.width + x) * this.channelLen;
                            r = index;
                            g = index + 1;
                            b = index + 2;
                            a = index + 3;
                            destImg.pixels[r] = srcPixels[r];
                            destImg.pixels[g] = srcPixels[g];
                            destImg.pixels[b] = srcPixels[b];
                            destImg.pixels[a] = srcPixels[a];
                        }
                    }
                    destImg.updatePixels();
                }
            
                flowLine(srcImg, obj) {
            
                    let destPixels,
                        tempY;
                    destPixels = new Uint8ClampedArray(srcImg.pixels);
                    obj.t1 %= srcImg.height;
                    obj.t1 += obj.speed;
                    //tempY = p.floor(noise(obj.t1) * srcImg.height);
                    tempY = p.floor(obj.t1);
                    for (let y = 0; y < srcImg.height; y++) {
                        if (tempY === y) {
                            for (let x = 0; x < srcImg.width; x++) {
                                let r, g, b, a;
                                let index;
                                index = (y * srcImg.width + x) * this.channelLen;
                                r = index;
                                g = index + 1;
                                b = index + 2;
                                a = index + 3;
                                destPixels[r] = srcImg.pixels[r] + obj.randX;
                                destPixels[g] = srcImg.pixels[g] + obj.randX;
                                destPixels[b] = srcImg.pixels[b] + obj.randX;
                                destPixels[a] = srcImg.pixels[a];
                            }
                        }
                    }
                    return destPixels;
                }
            
                shiftLine(srcImg) {
            
                    let offsetX;
                    let rangeMin, rangeMax;
                    let destPixels;
                    let rangeH;
            
                    destPixels = new Uint8ClampedArray(srcImg.pixels);
                    rangeH = srcImg.height;
                    rangeMin = p.floor(p.random(0, rangeH));
                    rangeMax = rangeMin + p.floor(p.random(1, rangeH - rangeMin));
                    offsetX = this.channelLen * p.floor(p.random(-40, 40));
            
                    for (let y = 0; y < srcImg.height; y++) {
                        if (y > rangeMin && y < rangeMax) {
                            for (let x = 0; x < srcImg.width; x++) {
                                    let r, g, b, a;
                                    let r2, g2, b2, a2;
                                    let index;
            
                                    index = (y * srcImg.width + x) * this.channelLen;
                                    r = index;
                                    g = index + 1;
                                    b = index + 2;
                                    a = index + 3;
                                    r2 = r + offsetX;
                                    g2 = g + offsetX;
                                    b2 = b + offsetX;
                                    destPixels[r] = srcImg.pixels[r2];
                                    destPixels[g] = srcImg.pixels[g2];
                                    destPixels[b] = srcImg.pixels[b2];
                                    destPixels[a] = srcImg.pixels[a];
                            }
                        }
                    }
                    return destPixels;
                }
            
                shiftRGB(srcImg) {
            
                    let randR, randG, randB;
                    let destPixels;
                    let range;
            
                    range = 16;
                    destPixels = new Uint8ClampedArray(srcImg.pixels);
                    randR = (p.floor(p.random(-range, range)) * srcImg.width + p.floor(p.random(-range, range))) * this.channelLen;
                    randG = (p.floor(p.random(-range, range)) * srcImg.width + p.floor(p.random(-range, range))) * this.channelLen;
                    randB = (p.floor(p.random(-range, range)) * srcImg.width + p.floor(p.random(-range, range))) * this.channelLen;
            
                    for (let y = 0; y < srcImg.height; y++) {
                        for (let x = 0; x < srcImg.width; x++) {
                            let r, g, b, a;
                            let r2, g2, b2, a2;
                            let index;
            
                            index = (y * srcImg.width + x) * this.channelLen;
                            r = index;
                            g = index + 1;
                            b = index + 2;
                            a = index + 3;
                            r2 = (r + randR) % srcImg.pixels.length;
                            g2 = (g + randG) % srcImg.pixels.length;
                            b2 = (b + randB) % srcImg.pixels.length;
                            destPixels[r] = srcImg.pixels[r2];
                            destPixels[g] = srcImg.pixels[g2];
                            destPixels[b] = srcImg.pixels[b2];
                            destPixels[a] = srcImg.pixels[a];
                        }
                    }
            
                    return destPixels;
                }
            
                getrandomRectImg(srcImg) {
                    let startX;
                    let startY;
                    let rectW;
                    let rectH;
                    let destImg;
                    startX = p.floor(p.random(0, srcImg.width - 30));
                    startY = p.floor(p.random(0, srcImg.height - 50));
                    rectW = p.floor(p.random(30, srcImg.width - startX));
                    rectH = p.floor(p.random(1, 50));
                    destImg = srcImg.get(startX, startY, rectW, rectH);
                    destImg.loadPixels();
                    return destImg;
                }
            
                show() {
                  
                    // restore the original state
                    this.replaceData(this.imgOrigin, this.copyData);
            
                    // sometimes pass without effect processing
                    let n = p.floor(p.random(100));
                    if (n > 75 && this.throughFlag) {
                        this.throughFlag = false;
                        setTimeout(() => {
                            this.throughFlag = true;
                        }, p.floor(p.random(40, 100)));
                    }
                    if (!this.throughFlag) {
                        p.push();
                        p.translate((p.width - this.imgOrigin.width) / 2, (p.height - this.imgOrigin.height) / 2);
                        p.image(this.imgOrigin, 0, 0);
                        p.pop();
                        return;
                    }
            
                    // flow line
                    this.flowLineImgs.forEach((v, i, arr) => {
                        arr[i].pixels = this.flowLine(this.imgOrigin, v);
                        if (arr[i].pixels) {
                            this.replaceData(this.imgOrigin, arr[i].pixels);
                        }
                    })
            
                    // shift line
                    this.shiftLineImgs.forEach((v, i, arr) => {
                        if (p.floor(p.random(100)) > 50) {
                            arr[i] = this.shiftLine(this.imgOrigin);
                            this.replaceData(this.imgOrigin, arr[i]);
                        } else {
                            if (arr[i]) {
                                this.replaceData(this.imgOrigin, arr[i]);
                            }
                        }
                    })
            
                    // shift rgb
                    this.shiftRGBs.forEach((v, i, arr) => {
                        if (p.floor(p.random(100)) > 65) {
                            arr[i] = this.shiftRGB(this.imgOrigin);
                            this.replaceData(this.imgOrigin, arr[i]);
                        }
                    })
            
                    p.push();
                    p.translate((p.width - this.imgOrigin.width) / 2, (p.height - this.imgOrigin.height) / 2);
                    p.image(this.imgOrigin, 0, 0);
                    p.pop();
            
                    // scat image
                    this.scatImgs.forEach((obj) => {
                        p.push();
                        p.translate((p.width - this.imgOrigin.width) / 2, (p.height - this.imgOrigin.height) / 2);
                        if (p.floor(p.random(100)) > 80) {
                            obj.x = p.floor(p.random(-this.imgOrigin.width * 0.3, this.imgOrigin.width * 0.7));
                            obj.y = p.floor(p.random(-this.imgOrigin.height * 0.1, this.imgOrigin.height));
                            obj.img = this.getrandomRectImg(this.imgOrigin);
                        }
                        if (obj.img) {
                            p.image(obj.img, obj.x, obj.y);
                        }
                        p.pop();
                    })
            
                }
            
            }

            p.setup = () => {
                let windowW = 1920;
                let windowH = 1080;
                p.background(0);
                const c = p.createCanvas(p.windowWidth - 15, p.windowHeight);
                p.loadImage('./glitch.png', function(img) {
                    img.resize(p.windowWidth + 20, p.windowHeight);
                    glitch = new Glitch(img);
                    isLoaded = true;
                });
            };

            p.draw = () => {
                p.clear();
                p.background(0);
            
                if (isLoaded) {
                    glitch.show();
                }
            
                // fill(255, 255, 255);
                // textSize(14);
                // text('FPS: ' + p5.floor(frameRate()), 20, 30);
            
            };
        })
    }

    render() {
        return (
            <div ref={this.renderRef}></div>
        );
    }
}

export default Sketch;