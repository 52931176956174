import { useState } from 'react';

const Button = (props) => {
	const [ hovering, setHovering ] = useState(false);

	const styles = {
		transparent: {
			padding: '0.63rem 1.9rem',
			textTransform: 'uppercase',
			border: `0.19rem solid ${hovering ? '#dbdbdb' : '#fff'}`,
			transform: hovering ? 'scale(1.03)' : '',
			cursor: 'pointer',
			background: 'transparent',
			color: hovering ? '#dbdbdb' : '#fff',
			fontSize: '1.25rem',
			fontWeight: 'bold',
			transition: '0.3s',
			minWidth: window.innerWidth <= 700 ? '20rem' : 0,
			marginBottom: window.innerWidth <= 700 ? '1rem' : 0
		},
		solid: {
			padding: '0.63rem 1.9rem',
			textTransform: 'uppercase',
			border: `0.19rem solid ${hovering ? '#3dff4d' : '#fff'}`,
			cursor: 'pointer',
			backgroundColor: hovering ? '#3dff4d' : '#fff',
			transform: hovering ? 'scale(1.03)' : '',
			fontSize: '1.25rem',
			color: window.innerWidth <= 700 ? '#9188FC' : '#5B9FFB',
			marginLeft: window.innerWidth <= 700 ? 0 : '0.63rem',
			fontWeight: 'bold',
			transition: '0.3s',
			minWidth: window.innerWidth <= 700 ? '20rem' : 0
		}
	};

	return (
		<button
			onMouseEnter={() => setHovering(true)}
			onMouseLeave={() => {
				setHovering(false);
			}}
			onClick={props.onClick}
			style={props.transparent ? styles.transparent : styles.solid}
		>
			{props.children}
		</button>
	);
};

export default Button;
