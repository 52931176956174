import { useEffect } from 'react';
import Typewriter from 'typewriter-effect/dist/core';

const GlitchedHeading = () => {
	const styles = {
		heading: {
			marginTop: '6.25rem',
			fontWeight: 600,
			fontSize: window.innerWidth <= 700 ? '2.5rem' : '3.5rem',
			marginBottom: 0,
			height: window.innerWidth <= 500 ? '8rem' : ''
		}
	};

	useEffect(() => {
		setTimeout(() => {
			new Typewriter('#text', {
				strings: [
					'Price Errors',
					'Freebies',
					'Fast Monitors',
					'Exclusive Deals',
					'Lowkey Flips',
					'Investments'
				],
				autoStart: true,
				loop: true,
				pauseFor: 3000
			});
		});
	}, []);

	return (
		<h1 className="heading" style={styles.heading}>
			Welcome to <strong id="text">Price Errors</strong>
		</h1>
	);
};

export default GlitchedHeading;
