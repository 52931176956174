import { useState, useEffect } from "react";
import { useRouter } from 'next/router';
import Button from "./Button.js";
import TypeHeading from "./TypeHeading.js";
import useSession from "../../hooks/useSession";
import PurchaseForm from "../Dashboard/purchaseForm";
import GlitchEffect from "./GlitchEffect";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { toast } from "react-toastify";
import useSWR from "swr";
import PasswordPage from "./PasswordPage";

const fetcher = (url) => {
  return fetch(url, {
    mode: "cors",
    headers: {
      Authorization: "Basic MTA2NTQ2ODkzMTcwOTU5OTc0NDpiZXN0ZGFzaA==",
    },
  }).then((r) => r.json());
};

const getProduct = (product) => {
  const { data, error } = useSWR(
    `https://hoc-api.apollodashboards.io/api/plans/find/${product}`,
    fetcher
  );
  return data;
};

const getRelease = (checkout) => {
  const { data, error } = useSWR(
    `https://hoc-api.apollodashboards.io/api/releases/find/${checkout}`,
    fetcher
  );
  return data;
};

const HeaderBody = () => {
  const [passwordModal, setPasswordModal] = useState(false);
  const [password, setPassword] = useState("");
  const [purchaseFormModal, setPurchaseFormModal] = useState(false);

  const router = useRouter();

  const { id } = router.query;

  const toggle = (closePurchase) => {
    const c = document.getElementsByClassName("p5Canvas");
    for (const e of c) {
      e.style["z-index"] = passwordModal || purchaseFormModal ? -1 : 3;
    }

	// if(closePurchase) {
		return setPurchaseFormModal(!purchaseFormModal);
	// }
  //   setPasswordModal(!passwordModal);
  };
  

  const release = getRelease(id ?? "651090950fc141a293ba36f4");
  const product = release?.release;
  const { session, error: sessionError } = useSession();

  const stripePromise = loadStripe(
    "pk_live_zt1pmZZja442UY6d95EVKKp900iWjUcZss"
  );

  useEffect(() => {
    // const x = setInterval(() => {
      if(session) {
        // clearInterval(x);
        if(sessionError || !session.user) {
          document.cookie = `ref=${document.URL}; expires=${Date.now()+(1000*60*60*24)}; path=/`;
          //window.location.assign('https://hoc-api.apollodashboards.io/login/signin');
          return;
        }
        if(session.user != null && product && product.maxUses > product.uses && new Date(product.startDate) <= Date.now()) {
          setPurchaseFormModal(true);
        }
      }
    // }, 1000);
  }, [session, product]);

  const purchaseClick = () => {
    console.log("purchase click");
    if (!session.user) {
      toast.error("Please log in to discord");
      return;
    }
    if(!product) {
      toast.error("Please wait...");
    }
    toggle();
  };

  if (product) {
    console.log(product.maxUses > product.uses, product.startDate <= Date.now());
  }

  const styles = {
    subheading: {
      marginTop: "0.63rem",
      fontWeight: 600,
      fontSize: window.innerWidth <= 700 ? "1.2rem" : "1.8rem",
      marginBlockEnd: '1em'
    },
    header_body: {
      display: "flex",
      justifyContent: window.innerWidth <= 700 ? "center" : "space-between",
      flexDirection: window.innerWidth <= 700 ? "column" : "",
      textAlign: window.innerWidth <= 700 ? "center" : "left",
      position: "relative",
    },
    button_transparent: {
      padding: "0.63rem 1.9rem",
      textTransform: "uppercase",
      border: "0.19rem solid #fff",
      cursor: "pointer",
      background: "transparent",
      color: "#fff",
      fontSize: "1.25rem",
      fontWeight: "bold",
    },
    button_solid: {
      padding: "0.63rem 1.9rem",
      textTransform: "uppercase",
      border: "0.19rem solid #fff",
      cursor: "pointer",
      backgroundColor: "#fff",
      fontSize: "1.25rem",
      color: "#5B9FFB",
      marginLeft: "0.63rem",
      fontWeight: "bold",
    },
    image: {
      width: "9.4rem",
    },
    gif_logo: {
      width: "20rem",
      borderRadius: "100%",
      marginTop: "4.69rem",
      marginRight: window.innerWidth <= 700 ? 0 : "6.25rem",
      border: "0.63rem solid #fff",
      display: 'inline',
    },
  };

  return (
    <div className="header-body" style={styles.header_body}>
	  {passwordModal && (
		<PasswordPage
			passwordModal={passwordModal}
			setPasswordModal={setPasswordModal}
			purchaseFormModal={purchaseFormModal}
			setPurchaseFormModal={setPurchaseFormModal}
			password={password}
      setPassword={setPassword}
      planID={product._id}
		/>
	  )}
      {purchaseFormModal && (
        <Elements stripe={stripePromise}>
          <PurchaseForm
            setPurchaseModal={setPurchaseFormModal}
            purchaseFormModal={purchaseFormModal}
            toggle={toggle}
            product={product.plan}
            release={product}
            password={password}
          />
        </Elements>
      )}
      <div>
        <TypeHeading />
        <h3 className="subheading" style={styles.subheading}>
          Revolutionizing the way people catch
          <br />
          Price Errors, Glitches, & Deals.
        </h3>

        {product && product.maxUses > product.uses && new Date(product.startDate) <= Date.now() ? (
          <Button onClick={() => purchaseClick()} transparent={false}>
            Purchase Membership
          </Button>
        ) : (
          <Button disabled={true} transparent={true}>
            Out Of Stock
          </Button>
        )}
        {!session.user && <Button transparent={false} onClick={() => {
          window.location = 'https://hoc-api.apollodashboards.io/login/signin';
        }}>Login</Button>}
      </div>
      <div>
        <img style={styles.gif_logo} src="/logo.gif" alt="Price Errors Logo" />
      </div>
      {/* <GlitchEffect /> */}
    </div>
  );
};

export default HeaderBody;
