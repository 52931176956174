import { Input, Flex, Avatar, Heading, Text, Button, FormControl } from "@chakra-ui/react";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const PasswordPage = (props) => {
  const {
    passwordModal,
    setPasswordModal,
    setPassword,
    password,
    purchaseFormModal,
    setPurchaseFormModal,
    planID
  } = props;

  const submitPassword = async(password, planID) => {
    try {
      const res = await fetch(`https://hoc-api.apollodashboards.io/api/plans/submitPassword`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          Authorization: "Basic MTA2NTQ2ODkzMTcwOTU5OTc0NDpiZXN0ZGFzaA==",
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          planID,
          password,
        })
      });
      
      if(res.status == 200) {
        return true;
      }
    } catch (e) {}

    return false;
  };
  
  // const [password, setPassword] = useState('');

  // const toggle = () => setPasswordModal(!passwordModal);

  const switchModals = async () => {
    console.log(password, props.password);
    if(await submitPassword(password, planID)) {
      setPasswordModal(!passwordModal);
      setPurchaseFormModal(true);
    } else {
      toast.error('incorrect password')
    }
  }

  const toggle = () => {
    const c = document.getElementsByClassName("p5Canvas");
    for (const e of c) {
      e.style["z-index"] = passwordModal || purchaseFormModal ? -1 : 3;
    }

    setPasswordModal(!passwordModal);
  };

  return (
    <div>
      {/* <Button color="danger" onClick={toggle}>
        // {buttonLabel}
      </Button> */}
      {/* <Modal isOpen={passwordModal} toggle={toggle} className={'passwordModal'}>
        <ModalHeader toggle={toggle}>Password</ModalHeader>
        <ModalBody>
          Please enter the correct password to purchase a membership.
          <Input placeholder="password" onChange={(e) => setPassword(e.target.value)} />
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => switchModals()}
          >
            Submit
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal> */}
       <Modal isOpen={passwordModal} toggle={toggle} backdropClassName={'pe-backdrop'}>
      <ModalBody>
        <Flex flexDirection="row" alignItems="center" justifyContent="center">
          <Flex
            alignSelf="center"
            alignItems="center"
            ml={"29%"}
            mb={4}
            flexDirection="column"
          >
            <Heading fontSize="2xl">Price Errors</Heading>
            <Text fontSize="md" fontWeight="bold">
              Password Page
            </Text>
          </Flex>
          <Flex
            alignItems="center"
            justifyContent="center"
            pl={"19%"}
            flexDirection="row"
          >
            <Button onClick={toggle} variant="link">
              x
            </Button>
          </Flex>
        </Flex>
        <FormControl>
          <Flex mb={4} flexDirection="column">
            <Flex flexDirection="column" pb={4}>
              <Text pl={2}>
              Please Enter the correct password to continue
              </Text>
              <Input
                placeholder="Password"
                border="2px"
                borderColor="#708090"
                borderRadius="10px"
                onChange={(e) => setPassword(e.target.value)}
              />
            </Flex>
          </Flex>
        </FormControl>
        <Flex flexDirection="column">
            <Button
              fontSize="lg"
              bgColor="#302e66"
              bg="transparent"
              color="#e2dff4"
              backgroundSize="200% auto"
              border="2px"
              type="submit"
              onClick={switchModals}
            >
              <Flex>
                <Text pl={2}>Submit</Text>
              </Flex>
            </Button>
        </Flex>
      </ModalBody>
    </Modal>
    </div>
  );
};

export default PasswordPage;
